import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=1060c071&scoped=true"
import script from "./Notifications.vue?vue&type=script&lang=js"
export * from "./Notifications.vue?vue&type=script&lang=js"
import style0 from "../css/pages/notifications.scss?vue&type=style&index=0&id=1060c071&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1060c071",
  null
  
)

export default component.exports