<template>
	<div>
		<div class="container">
			<h1 class="title-search">{{$t("titles.notifs")}}</h1>
			<div class="filters">
				<div class="input-group blocktype margin-left-fix" :class="{ over: filterTypeOver }">
					<div class="input-group-prepend">
						<div class="input-group-text">{{ $t('common.type') }}</div>
					</div>
					<Popper
						class="dropdown d-inline-block filter-type-dropdown"
						trigger="clickToToggle"
						:options="{ placement: 'bottom-end', modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }] }"
						:visible-arrow="false"
						@show="$app.showFilter( true ); filterTypeOver = true"
						@hide="$app.showFilter( false ); filterTypeOver = false"
						ref="typePopper"
					>
						<div class="dropdown-menu" @click="$refs.typePopper.doClose()">
							<div class="dropdown-choices">
								<button class="dropdown-item" v-for="f in filters" @click="filterType = f; filterNotif = -1">{{ $t('data.notificationfilters')[f].name }}</button>
							</div>
						</div>
						<button
							slot="reference"
							ref="typeSelector"
							aria-expanded="false"
							aria-haspopup="true"
							class="btn btn-secondary dropdown-toggle"
							data-flip="false"
							data-toggle="dropdown"
						>
							{{ $t('data.notificationfilters')[filterType].name }}
						</button>
					</Popper>
				</div>
				<div v-if="$store.state.domain == 'esthetics'" class="input-group">
					<div class="input-group-prepend">
						<div class="input-group-text">{{ $t('common.worker') }}</div>
					</div>
					<WorkersDropdown :general-text="$t('common.allworkers')" @change="filterWorker = $event"/>
				</div>
				<div
					v-if="filterType !== 'all' || $store.state.domain == 'coachs'"
					class="input-group blocktype"
					:class="{ over: notifTypeOver }"
				>
					<div class="input-group-prepend">
						<div class="input-group-text">{{ $t('common.notification') }}</div>
					</div>
					<Popper
						class="dropdown d-inline-block filter-type-dropdown"
						trigger="clickToToggle"
						:options="{ placement: 'bottom-end', modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }] }"
						:visible-arrow="false"
						@show="$app.showFilter( true ); notifTypeOver = true"
						@hide="$app.showFilter( false ); notifTypeOver = false"
						ref="notifPopper"
					>
						<div class="dropdown-menu" @click="$refs.notifPopper.doClose()">
							<div class="dropdown-choices">
								<button
									class="dropdown-item"
									@click="filterNotif = -1"
								>
									{{ $t('common.all') }}
								</button>
								<button
									v-for="[id, notif] in notificationsFilterCurrentType"
									v-if="notif.message.indexOf('worker') >= 0 || notif.message.indexOf('user') < 0"
									:ref="`type-${id}`"
									class="dropdown-item"
									@click="filterNotif = id"
								>
									«
									<i18n :path="`data.notifications['${id}'].message`">
										<template v-slot:date>
										</template>
										<template v-slot:worker>
											<i class="worker">{{ $t('common.worker').toLowerCase() }}</i>
										</template>
										<template v-slot:user>
											<i class="client">{{ $t('common.client').toLowerCase() }}</i>
										</template>
										<template v-slot:data.user>
											<i class="client">{{ $t('common.client').toLowerCase() }}</i>
										</template>
										<template v-slot:action>
										<b>{{ $t( `data.notifications['${id}'].action`).toLowerCase() }}</b>
										</template>
										<template v-slot:appdate>
											<i>{{ $t('common.date') }}</i>
										</template>
									</i18n>
									»
								</button>
								<button
									v-for="[id, notif] in notificationsFilterCurrentType"
									v-if="notif.message.indexOf('worker') < 0 && notif.message.indexOf('user') >= 0"
									:ref="`type-${id}`"
									class="dropdown-item"
									@click="filterNotif = id"
								>
									«
									<i18n :path="`data.notifications['${id}'].message`">
										<template v-slot:date>
										</template>
										<template v-slot:worker>
											<i class="worker">{{ $t('common.worker').toLowerCase() }}</i>
										</template>
										<template v-slot:user>
											<i class="client">{{ $t('common.client').toLowerCase() }}</i>
										</template>
										<template v-slot:data.user>
											<i class="client">{{ $t('common.client').toLowerCase() }}</i>
										</template>
										<template v-slot:action>
										<b>{{ $t( `data.notifications['${id}'].action`).toLowerCase() }}</b>
										</template>
										<template v-slot:appdate>
											<i>{{ $t('common.date') }}</i>
										</template>
									</i18n>
									»
								</button>
							</div>
						</div>
						<button
							slot="reference"
							ref="notifSelector"
							aria-expanded="false"
							aria-haspopup="true"
							class="btn btn-secondary dropdown-toggle"
							data-flip="false"
							data-toggle="dropdown"
						>
							<template v-if="filterNotif >= 0">
								«
								<i18n :path="`data.notifications['${filterNotif}'].message`">
									<template v-slot:date>
									</template>
									<template v-slot:worker>
										<i class="worker">{{ $t('common.worker').toLowerCase() }}</i>
									</template>
									<template v-slot:user>
										<i class="client">{{ $t('common.client').toLowerCase() }}</i>
									</template>
									<template v-slot:data.user>
										<i class="client">{{ $t('common.client').toLowerCase() }}</i>
									</template>
									<template v-slot:action>
									<b>{{ $t( `data.notifications['${filterNotif}'].action`).toLowerCase() }}</b>
									</template>
									<template v-slot:appdate>
										<i>{{ $t('common.date') }}</i>
									</template>
								</i18n>
								»
							</template>
							<template v-else>
								{{ $t('common.all') }}
							</template>
						</button>
					</Popper>
				</div>
			</div>
			<div class="filters">
				<div class="input-group form-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("common.sdate")}}</span>
					</div>
					<div ref="date1" id="date1" class="input-group date" data-target-input="nearest">
						<input class="form-control datetimepicker-input" data-target="#date1" name="date1" onkeydown="return false" type="text">
						<span class="input-group-append">
							<div v-if="filterDateStart" class="input-group-text btn btn-secondary" @click="filterDateStart = null; resetMinDate()">
								<i class="fa fa-times"></i>
							</div>
							<div class="input-group-text calendar-bg btn btn-secondary" data-target="#date1" data-toggle="datetimepicker">
								<i class="fa fa-calendar"></i>
							</div>
						</span>
					</div>
				</div>
				<div class="input-group form-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("common.edate")}}</span>
					</div>
					<div ref="date2" id="date2" class="input-group date" data-target-input="nearest">
						<input class="form-control datetimepicker-input" data-target="#date2" name="date2" onkeydown="return false" type="text">
						<span class="input-group-append">
							<div v-if="filterDateEnd && filterDateEnd != dayjs().format('YYYY-MM-DD')" class="input-group-text btn btn-secondary" @click="filterDateEnd = null; resetMaxDate()">
								<i class="fa fa-times"></i>
							</div>
							<div class="input-group-text calendar-bg btn btn-secondary" data-target="#date2" data-toggle="datetimepicker">
								<i class="fa fa-calendar"></i>
							</div>
						</span>
					</div>
				</div>
			</div>
			<NotificationsList
				ref="notifs"
				:full="true"
				:max="50"
				:type-filter="notificationsFilter"
				:workerid="parseInt(filterWorker)"
				:mindate="filterDateStart"
				:maxdate="filterDateEnd && dayjs(filterDateEnd).add(1, 'day').format('YYYY-MM-DD')"
			/>
		</div>
	</div>
</template>

<script>
	import dayjs from "dayjs"
	import Popper from "vue-popperjs"
	import WorkersDropdown from "../components/WorkersDropdown.vue"
	import NotificationsList from "../components/NotificationsList.vue"

	export default {
		components: {
			Popper,
			WorkersDropdown,
			NotificationsList
		},
		data() {
			return {
				dayjs,
				filterTypeOver: false,
				filterType: 'all',
				filterWorker: null,
				filterDateStart: null,
				filterDateEnd: null,
				notifTypeOver: false,
				filterNotif: -1,
			}
		},
		computed: {
			filters() {
				let keys = Object.keys(this.$t('data.notificationfilters'))
				if (!this.$store.getters.salonHasStocks())
					keys = keys.filter(f => !this.$t('data.notificationfilters')[f].stocks)
				if (this.$store.state.salon.subscription === 'starter')
					keys = keys.filter(f => !this.$t('data.notificationfilters')[f].premium)
				if (!this.$store.state.worker.admin)
					keys = keys.filter(f => !this.$t('data.notificationfilters')[f].admin)

				return keys
			},
			notificationFilterMap() {
				return Object.entries(this.$t('data.notifications'))
					.filter(([, notif]) => {
						if (notif.admin && !this.$store.state.worker.admin)
							return false
						if (notif.premium && this.$store.state.salon.subscription === 'starter')
							return false
						if (notif.stocks && !this.$store.getters.salonHasStocks())
							return false
						return true
					})
					.reduce((acc, [id, notif]) => {
						(notif.filters || []).forEach(f => {
							acc[f] = [...(acc[f] || []), id]
						})
						return acc
					}, {})
			},
			notificationsFilter() {
				if (this.filterNotif >= 0)
					return [this.filterNotif]
				if (this.filterType === 'all')
					return null
				return [...this.notificationFilterMap[this.filterType]]
			},
			notificationsFilterCurrentType() {
				if (this.filterType === 'all')
					return {}
				return Object.entries(this.$t('data.notifications'))
					.filter(([k]) => this.notificationFilterMap[this.filterType].includes(k))
			},
		},
		watch: {
			'$route'( to, from ) {
				if ( to.path == "/notifications" ) {
					// Reload infinite loader
					this.$nextTick( () => {
						this.$refs.notifs.reload()
						window.scrollTo( window.scrollX, window.scrollY - 1 )
						window.scrollTo( window.scrollX, window.scrollY + 1 )
					})
				}
			}
		},
		methods: {
			resetMinDate() {
				$(this.$refs.date1).find( "input" ).val( "" )
			},
			resetMaxDate() {
			//	$(this.$refs.date2).datetimepicker( "date", new Date() )
			//	this.filterDateEnd = dayjs().format( "YYYY-MM-DD" )
			//	$(this.$refs.date2).find( "input" ).val( dayjs().format("DD MMM YYYY") )
				$(this.$refs.date2).find( "input" ).val( "" )
			},
			update() {
				this.$refs.notifs.reload()
			}
		},
		mounted() {
			$(this.$refs.date1).datetimepicker({
				format: "DD MMM YYYY",
				maxDate: dayjs().format( "YYYY-MM-DD" ),
				widgetPositioning: { vertical: "bottom", horizontal: "right" },
				debug: ( process.env.VUE_APP_MODE === "development" ),
				icons: { "time": "fa fa-clock" },
				locale: this.$i18n.locale,
				useCurrent: false
			})
			$(this.$refs.date1).on( "change.datetimepicker", (e) => {
				this.filterDateStart = e.date.format( "YYYY-MM-DD" )
			})

			$(this.$refs.date2).datetimepicker({
				format: "DD MMM YYYY",
				maxDate: dayjs().format( "YYYY-MM-DD" ),
				widgetPositioning: { vertical: "bottom", horizontal: "right" },
				debug: ( process.env.VUE_APP_MODE === "development" ),
				icons: { "time": "fa fa-clock" },
				locale: this.$i18n.locale,
				useCurrent: false
			})
			$(this.$refs.date2).on( "change.datetimepicker", (e) => {
				this.filterDateEnd = e.date.format( "YYYY-MM-DD" )
			})

			// Reload infinite loader
			this.$nextTick( () => {
				window.scrollTo( window.scrollX, window.scrollY - 1 )
				window.scrollTo( window.scrollX, window.scrollY + 1 )
			})
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/notifications.scss"/>
